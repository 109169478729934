import React from 'react';
import PropTypes from 'prop-types';

// components
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Section from '../components/Section';
import Container from '../components/Container';

const TermsPage = ({ location }) => (
  <Layout pageId="terms" location={location} darkHeader={true}>
    <SEO title="Terms & Conditions" />

    <Section id="terms" backgroundColor="#FFF">
      <Container className="flex justify-start items-center pb-0 lg:pb-0 xl:pb-0">
        <div className="w-full xl:w-9/12 mx-auto text-black">
          <h1 className="ff-secondary fs-28 text-center text-black mb-5 font-bold">
            <span>Privacy Policy</span>
          </h1>
          <p>
            <span>
              This Privacy Policy describes Hie HQ (Hereinafter referred to as
              the &ldquo;Company&rdquo;) policies and procedures on the
              collection and use and disclosure of the Information provided by
              the Users and visitors of the Website (together referred to as the
              &ldquo;Users&rdquo;). The Company shall not use the User&rsquo;s
              information in any manner except as provided under this Privacy
              Policy. Every User who accesses the Website agrees to be bound by
              the terms of this Privacy Policy. Interpretation: In this Privacy
              Policy, references to &ldquo;You&rdquo;, &ldquo;Your&rdquo;,
              &ldquo;User&rdquo; shall mean the end user accessing the Website
              or the Services and &ldquo;We&rdquo;, &ldquo;Us&rdquo; and
              &ldquo;Our&rdquo; shall mean the Company, its affiliates and
              partners.
            </span>
          </p>
          <h3>
            <span>Why this Privacy Policy?</span>
          </h3>
          <p>
            <span>This privacy policy is published in compliance of:</span>
          </p>
          <ul>
            <li>
              <span>Section 43A Of The Information Technology Act, 2000</span>
            </li>
            <li>
              <span>
                Regulation 4 Of The Information Technology (Reasonable Security
                Practices And Procedures And Sensitive Personal Information)
                Rules, 2011 (The &ldquo;SPI Rules&rdquo;) And
              </span>
            </li>
            <li>
              <span>
                Regulation 3(1) Of The Information Technology (Intermediaries
                Guidelines) Rules, 2011.
              </span>
            </li>
          </ul>
          <h3>
            <span>What is Personal Information?</span>
          </h3>
          <p>
            <span>
              &ldquo;Personal information&rdquo; is defined under the SPI Rules
              to mean any information that relates to a natural person, which,
              either directly or indirectly, in combination with other
              information available or likely to be available with a body
              corporate, is capable of identifying such a natural person.
              Information that is freely available in the public domain or
              accessible under the Right to Information Act, 2005 or any other
              law will not be regarded as sensitive personal data or
              information.the SPI rules further define &ldquo;sensitive personal
              data or information&rdquo; of a person to mean personal
              information about that person relating to:
            </span>
          </p>
          <ul>
            <li>
              <span>Passwords</span>
            </li>
            <li>
              <span>
                Financial Information Such As Bank Accounts, Credit And Debit
                Card Details Or Other Payment Instrument Details
              </span>
            </li>
            <li>
              <span>Physical, Physiological And Mental Health Condition</span>
            </li>
            <li>
              <span>Sexual Orientation</span>
            </li>
            <li>
              <span>Medical Records And History</span>
            </li>
            <li>
              <span>Biometric Information</span>
            </li>
            <li>
              <span>
                Information Received By Body Corporate Under Lawful Contract Or
                Otherwise
              </span>
            </li>
            <li>
              <span>
                Visitor Details As Provided At The Time Of Registration Or
                Thereafter And
              </span>
            </li>
            <li>
              <span>Call Data Records.</span>
            </li>
          </ul>
          <h3>
            <span>Information we collect</span>
          </h3>
          <p>
            <span>
              We collect Information through our Website to provide better
              services and results to our Users. Information is collected by the
              details provided by User and the data collected by the usage of
              the services. Details provided by User shall include Personal
              Information like name, address (residential or email), a contact
              number, and other such data which is required for the efficient
              use of the services provided by the Company. In general, the user
              can browse the Website without revealing any Personal Information.
              Although once Personal Information is provided, the identity of
              the User is not anonymous anymore. Where possible, we indicate
              which fields are required and which fields are optional. Our User
              always has the option of not providing Information by choosing not
              to use a particular service or feature. Other Information
              collected includes device information i.e. from which device is
              the services being used. This shall include the hardware model,
              operating system, unique identification number associated with the
              device, etc.
            </span>
          </p>
          <h3>
            <span>Usage of the collected Information</span>
          </h3>
          <p>
            <span>
              The Information collected is constantly used to provide personally
              relevant features and improve the services for our Users. With
              such a pool of information, it is easier for us to understand the
              current trends in the market, according to which we cater our
              services. Such information helps us to come with content that
              provides a better User experience, thereby creating better
              satisfaction and increasing the user base of our Website. User
              discretion is asked before using such information for any other
              purposes than those set out in this Policy. Although the
              information which is shared by the User themselves through us
              shall not be considered as making private information public.
            </span>
          </p>
          <h3>
            <span>Sharing of Personal Information</span>
          </h3>
          <p>
            <span>
              Disclosure may be necessary to provide Users access to our
              Services, to comply with our legal obligations, to enforce our
              User Agreement, to prevent, detect, mitigate, and investigate
              fraudulent or illegal activities related to our Services. Such
              Personal Information, when shared with third parties, shall be
              subject to strict confidentiality agreements
            </span>
          </p>
          <p>
            <span>
              We may disclose personal information if required to do so by law
              or in the good faith belief that such disclosure is reasonably
              necessary to respond to subpoenas, court orders, or other legal
              process. We may disclose Personal Information to law enforcement
              offices, third party rights owners, or others in the good faith
              belief that such disclosure is reasonably necessary to enforce our
              Terms or Privacy Policy. We and our affiliates will share / sell
              some or all of your personal information with another business
              entity should we (or our assets) plan to merge with, or be
              acquired by that business entity, or reorganization, amalgamation,
              restructuring of business. Should such a transaction occur that
              other business entity (or the new combined entity) will be
              required to follow this privacy policy with respect to your
              Personal Information.
            </span>
          </p>
          <h3>
            <span>Securing Information</span>
          </h3>
          <p>
            <span>
              In order to secure the information provided to us by our users, we
              review the processing and storage practices along with the
              information collected to guard against unauthorized access into
              the data collected in our systems. Furthermore, user information
              is protected by subjecting our Employees and Agents who process
              such information to strict confidentiality agreements, where if
              they fail to comply with it, such non compliance shall lead to
              heavy penalties or legal proceedings, depending upon the gravity
              of the situation.
            </span>
          </p>
          <h3>
            <span>Choice/Opt-Out</span>
          </h3>
          <p>
            <span>
              We provide all our users with the opportunity to opt-out of
              receiving promotional, marketing-related communications from us in
              general, after setting up an account. User may remove their
              personal information by unsubscribing or deleting their account
              from our Website.
            </span>
          </p>
          <h3>
            <span>Consent</span>
          </h3>
          <p>
            <span>
              By using the Website and by providing personal information, the
              user consents to the collection and use of the information
              disclosed by them in accordance with this Privacy Policy,
              including but not limited to their consent for sharing their
              information as per this privacy policy. Our Website offers
              publicly accessible blogs or community forums. You should be aware
              that any information you provide in these areas may be read,
              collected, and used by others who access them.
            </span>
          </p>
          <p>
            <span>
              This document is an electronic record in terms of existing and
              applicable Information Technology laws and the amended provisions
              thereto pertaining to electronic records in various allied
              statutes as amended pursuant to theInformation Technology laws.
              This electronic record has been generated by a computer system and
              does not require any authentication. From time to time, the
              Company may change this internet privacy policy. The effective
              date of thispolicy, as stated below, indicates the last time this
              policy was revised or materially changed. Checking the effective
              date below allows you to determine whether there have been changes
              since the last time you reviewed the policy.Effective date of this
              policy: This policy was last updated on
            </span>
          </p>
          <h1>
            <span>Terms of Use</span>
          </h1>
          <p>
            <span>
              These Terms of Use (&ldquo;Terms of Use&rdquo;) have been drafted
              in accordance with the provisions of Rule 3 (1) of the Information
              Technology (Intermediaries guidelines) Rules, 2011 that require
              publishing the rules and regulations, privacy policy and Terms of
              Use for access or usage of
            </span>{' '}
            <a href="https://hiehq.com">
              <span>https://hiehq.com</span>
            </a>
          </p>
          <p>
            <span>
              ACCESSING, BROWSING OR OTHERWISE USING THE WEBSITE INDICATES YOUR
              ACCEPTANCE TO ALL THE TERMS AND CONDITIONS HEREIN. PLEASE READ
              THESE TERMS OF USE CAREFULLY BEFORE PROCEEDING. IF YOU DO NOT
              AGREE WITH THE SAME, PLEASE DO NOT USE THIS WEBSITE.
            </span>
          </p>
          <p>
            <span>
              For the purpose of these Terms of Use, wherever the context so
              requires &ldquo;You&rdquo; or &ldquo;User&rdquo; shall mean any
              natural or legal person visits our platform, either just for the
              purpose of browsing the Website or engages to buy our products
              &amp; services. This Website allows the User to surf the Website.
              The term &ldquo;We&rdquo;, &ldquo;Us&rdquo;, &ldquo;Our&rdquo;
              shall mean Company.
            </span>
          </p>
          <p>
            <span>
              Your use of the Website and services and tools are governed by the
              following terms and conditions (&ldquo;Terms of Use&rdquo;) as
              applicable to the Website including the applicable policies which
              are incorporated herein by way of reference. If You transact on
              the Website, You shall be subject to the policies that are
              applicable to the Website for such transaction. By mere use of the
              Website, You shall be contracting with Company and these terms and
              conditions including the policies constitute your binding
              obligations, with the Website. Any new features or tools which are
              added to the current store shall also be subject to the Terms of
              Use. We reserve the right to update, change or replace any part of
              these Terms of Use by posting updates and/or changes to Our
              Website. It is your responsibility to check this page periodically
              for changes.
            </span>
          </p>
          <h3>
            <span>Eligibility to use</span>
          </h3>
          <p>
            <span>
              By agreeing to these Terms of Use, you represent that you are
              persons who can form legally binding contracts under Indian
              Contract Act, 1872. Persons who are &ldquo;incompetent to
              contract&rdquo; within the meaning of the Indian Contract Act,
              1872 including minors, un-discharged insolvents etc. are not
              eligible to use the Website. As a minor if you wish to use or
              transact on Website, such use or transaction may be made by your
              legal guardian or parents on the Website. Company reserves the
              right to refuse You access to the Website if it is brought to
              Company&rsquo;s notice or if it is discovered that you are under
              the age of 18 years. By visiting Company Website or accepting
              these Terms of Use, You represent and warrant to Company that You
              are 18 years of age or older, and that You have the right,
              authority and capacity to use the Website and agree to and abide
              by these Terms of Use. You also represent and warrant to the
              Company that You will use the Website in a manner consistent with
              any and all applicable laws and regulations.
            </span>
          </p>
          <h3>
            <span>Privacy and Information Protection</span>
          </h3>
          <p>
            <span>
              ACCESSING, BROWSING OR OTHERWISE USING THE WEBSITE INDICATES YOUR
              ACCEPTANCE TO ALL THE TERMS AND CONDITIONS HEREIN. PLEASE READ
              THESE TERMS OF USE CAREFULLY BEFORE PROCEEDING. IF YOU DO NOT
              AGREE WITH THE SAME, PLEASE DO NOT USE THIS WEBSITE.
            </span>
          </p>
          <p>
            <span>
              Please review our Privacy Policy, which also governs your visit to
              this Site, to understand our internal policies and practices. The
              personal information / data provided to us by you during the
              course of usage of
            </span>{' '}
            <a href="https://hiehq.com">
              <span>https://hiehq.com</span>
            </a>{' '}
            <span>
              will be treated as strictly confidential and in accordance with
              the Privacy Policy and applicable laws and regulations. If You
              object to your information being transferred or used, please do
              not use the website.
            </span>
          </p>
          <h3>
            <span>Charges for using the Website</span>
          </h3>
          <p>
            <span>
              Company does not charge any fee for browsing the Website. Company
              reserves the right to change its Fee Policy from time to time. In
              particular, Company may at its sole discretion introduce new
              services and modify some or all of the existing services offered
              on the Website. In such an event Company reserves the right to
              introduce fees for the new services offered or amend/introduce
              fees for existing services, as the case may be. Changes to the Fee
              Policy shall be posted on the Website and such changes shall
              automatically become effective immediately after they are posted
              on the Website.&nbsp;
            </span>
          </p>
          <h3>
            <span>Rights of use</span>
          </h3>
          <p>
            <span>
              Company grants you limited rights to access and make personal use
              of this website, but not to download (other than page caching) or
              modify it, or any portion of it. These rights do not include any
              commercial use of this website or its contents; any collection and
              use of any content, descriptions, or prices; any derivative use of
              this website or its contents; any downloading or copying of
              account information for the benefit of a third-party; or any use
              of data mining, robots, or similar data gathering and extraction
              tools.
            </span>
          </p>
          <p>
            <span>
              This website or any portion of this website (including but not
              limited to any copyrighted material, trademarks, or other
              proprietary information) may not be reproduced, duplicated,
              copied, sold, resold, visited, distributed or otherwise exploited
              for any commercial purpose.
            </span>
          </p>
          <h3>
            <span>Your Conduct</span>
          </h3>
          <p>
            <span>
              You must not use the Website in any way that causes, or is likely
              to cause, the Website or access to it to be interrupted, damaged
              or impaired in any way. You understand that you, and not Company,
              are responsible for all electronic communications and content sent
              from your computer to us and you must use the Website for lawful
              purposes only. Software Viruses, Political Campaigning, Commercial
              Solicitation, Chain Letters, Mass Mailings Or Any
              &ldquo;Spam&rdquo;; Or Violates Any Law For The Time Being In
              Force; Or Deceives Or Misleads The Addressee About The Origin Of
              Such Messages Or Communicates Any Information Which Is Grossly
              Offensive Or Menacing In Nature;
            </span>
          </p>
          <h3>
            <span>Policies</span>
          </h3>
          <h4>
            <span>Disclaimer of Warranties and Liabilities</span>
          </h4>
          <p>
            <span>
              We expressly disclaim any warranties or representations (express
              or implied) in respect of quality, suitability, accuracy,
              reliability, completeness, timeliness, performance, safety,
              merchantability, fitness for a particular purpose, or legality of
              the products listed or displayed or the content (including product
              information and/or specifications) on the Website. While we have
              taken precautions to avoid inaccuracies in content, this Website,
              all content, information, software, products, services and related
              graphics are provided as is, without warranty of any kind.
            </span>
          </p>
          <h4>
            <span>Indemnity and Release</span>
          </h4>
          <p>
            <span>
              You shall indemnify and hold harmless Company, its subsidiaries,
              affiliates and their respective officers, directors, agents and
              employees, from any claim or demand, or actions including
              reasonable attorney&rsquo;s fees, made by any third party or
              penalty imposed due to or arising out of your breach of these
              Conditions of Use or any document incorporated by reference, or
              your violation of any law, rules, regulations or the rights of a
              third party.
            </span>
          </p>
          <p>
            <span>
              You hereby expressly release Company and/or its affiliates and/or
              any of its officers and representatives from any cost, damage,
              liability or other consequence of any of the actions/inactions of
              the vendors and specifically waiver any claims or demands that you
              may have in this behalf under any statute, contract or otherwise.
            </span>
          </p>
          <h4>
            <span>Communications</span>
          </h4>
          <p>
            <span>When you visit </span>{' '}
            <a href="https://hiehq.com">
              <span>https://hiehq.com</span>
            </a>{' '}
            <span>
              or send e-mails to us, you are communicating with us
              electronically. We may communicate with you by e-mail, SMS, phone
              call or by posting notices on the website or by any other mode of
              communication. For contractual purposes, you consent to receive
              communications including SMS, e-mail or phone calls from us.
            </span>
          </p>
          <h4>
            <span>Losses</span>
          </h4>
          <p>
            <span>
              We will not be responsible for any business loss (including loss
              of profits, revenue, contracts, anticipated savings, data,
              goodwill or wasted expenditure) or any other indirect or
              consequential loss that is not reasonably foreseeable to both you
              and us when you commenced using the website.
            </span>
          </p>
          <h4>
            <span>Alteration of Service or Amendments to the Conditions</span>
          </h4>
          <p>
            <span>
              We reserve the right to make changes to our website, policies, and
              these Conditions of Use at any time. You will be subject to the
              policies and Conditions of Use in force at the time that you use
              the website, unless any change to those policies or these
              conditions is required to be made by law or government authority.
              If any of these conditions is deemed invalid, void, or for any
              reason unenforceable, that condition will be deemed severable and
              will not affect the validity and enforceability of any remaining
              condition.
            </span>
          </p>
          <h4>
            <span>Events beyond our reasonable control</span>
          </h4>
          <p>
            <span>
              We will not be held responsible for any delay or failure to comply
              with our obligations under these conditions if the delay or
              failure arises from any cause which is beyond our reasonable
              control. This condition does not affect your statutory rights.
            </span>
          </p>
          <h4>
            <span>Waiver</span>
          </h4>
          <p>
            <span>
              If you breach these conditions and we take no action, we will
              still be entitled to use our rights and remedies in any other
              situation where you breach these conditions.
            </span>
          </p>
          <h4>
            <span>Governing law and Jurisdiction</span>
          </h4>
          <p>
            <span>
              These conditions are governed by and construed in accordance with
              the laws of India. You agree, as we do, to submit to the exclusive
              jurisdiction of the courts at Bangalore.
            </span>
          </p>
          <p>
            <span>
              IN NO EVENT SHALL HIE HQ BE LIABLE FOR ANY SPECIAL, INCIDENTAL,
              INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND IN CONNECTION WITH
              THESE TERMS OF USE, EVEN IF USER HAS BEEN INFORMED IN ADVANCE OF
              THE POSSIBILITY OF SUCH DAMAGES.
            </span>
          </p>
          <h4>
            <span>Severability &amp; Waiver Contact Information</span>
          </h4>
          <p>
            <span>
              If any provision of this Terms of Use is held to be invalid or
              unenforceable, such provision shall be struck and the remaining
              provisions shall be enforced.
            </span>
          </p>
          <h4>
            <span>Contact Information</span>
          </h4>
          <p>
            <span>
              This site is owned and operated by Hie HQ. If you have any
              question, issue, complaint regarding any of our services, please
              contact <a href="mailto:contact@hiehq.com">contact@hiehq.com</a>
            </span>
          </p>
        </div>
      </Container>
    </Section>
  </Layout>
);

TermsPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default TermsPage;
